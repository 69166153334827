export const ActionTypes = {
	FETCH_PRODUCTS:"FETCH_PRODUCTS",
	SET_PRODUCT_DETAIL:"SET_PRODUCT_DETAIL",
	RESET_PRODUCT_DETAIL:"RESET_PRODUCT_DETAIL",
	CART_STATUS:"CART_STATUS",
	ADD_ITEM_TO_CART:"ADD_ITEM_TO_CART",
	CART_REMOVE_ITEM:"CART_REMOVE_ITEM",
	INCREASE_ITEM:"INCREASE_ITEM",
	DECREASE_ITEM:"DECREASE_ITEM",  
  CART_SAVE_SHIPPING_ADDRESS:"CART_SAVE_SHIPPING_ADDRESS",
  CART_SAVE_PAYMENT_METHOD:"CART_SAVE_PAYMENT_METHOD",
  CLEAR_CART_ITEM:"CLEAR_CART_ITEM",
  CART_REMOVE_SHIPPING_ADDRESS:"CART_REMOVE_SHIPPING_ADDRESS",
  RESET_PRODUCTS:"RESET_PRODUCTS"
}


export const UserConstants = {
  USER_LIST_RESET:"USER_LIST_RESET:",
  USER_LOGIN_FAIL:"USER_LOGIN_FAIL:",
  USER_LOGIN_REQUEST:"USER_LOGIN_REQUEST:",
  USER_LOGIN_SUCCESS:"USER_LOGIN_SUCCESS:",
  USER_REGISTER_FAIL:"USER_REGISTER_FAIL:",
  USER_REGISTER_REQUEST:"USER_REGISTER_REQUEST:",
  USER_REGISTER_SUCCESS:"USER_REGISTER_SUCCESS:",
  USER_REGISTER_SUCCESS_RESET:"USER_REGISTER_SUCCESS_RESET:",
  USER_LOGOUT:"USER_LOGOUT:"
}

export const OrderConstants = {
  ORDER_CREATE_REQUEST:"ORDER_CREATE_REQUEST:",
  ORDER_CREATE_SUCCESS:"ORDER_CREATE_SUCCESS:",
  ORDER_CREATE_FAIL:"ORDER_CREATE_FAIL:"
}